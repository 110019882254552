<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>
        Rating History
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="color1 color1Text--text"
        small fab
        @click.stop="$emit('close')"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pb-0">
      <div>
        <b>
          {{player.name}} currently holds a rating of {{rating.currentRating | ratingDisplay}}
        </b>
        for finishing {{rating.finish | ordinal}} in {{rating.division}} at {{rating.tournament}} on {{rating.dtEarned.format('L')}}
      </div>
      <v-divider></v-divider>
      If a higher rating is not earned the following downgrades will happen:
      <v-card class="my-2">
        <v-card-text class="pa-0">
          <v-simple-table dense>
            <tbody>
              <tr
                v-for="(r, i) in rating.values"
                :key="r.value"
              >
                <td :class="{'grey--text': r.valid, 'error--text': !r.valid}">{{r.valid ? 'Will be d' : 'D'}}owngraded to {{rating.getNext(i) | ratingDisplay}} on {{r.validTill |shortDate}}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <div></div>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions class="pt-0 justify-center">
      <v-btn
        color="color3"
        text small
        @click.stop="$emit('show-all')"
        v-if="more"
      >
        show all {{player.firstName}}'s ratings
      </v-btn>
    </v-card-actions>
    <v-card-actions class="pt-0 justify-end">
      <v-btn
        color="color3"
        text small
        :to="{ name: 'ratings-explained' }"
      >
        what are ratings?
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ['player', 'active', 'rating', 'more'],
  data () {
    return {
    }
  },
  computed: {
    ratings () {
      return this.showAll ? this.player.ratings : [this.player.ratings[0]]
    },
    test () {
      return this.player.vblRatings.ratingsByAge
    }
  },
  methods: {
    getRatings () {
      console.log(this.player.ratings)
      this.loading = true
      const p = this.player
      this.$VBL.ratings.getPlayer(p.id)
        .then(r => {
          const dto = r.data.find(f => f.id === p.id && f.systemId === p.systemId)
          p.update({
            ratings: dto.ratings
          })
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
