<template>
  <div
    class="clickable"
    :class="`rating-${$vuetify.breakpoint.mdAndUp ? 'md' : 'sm'}`"
    v-if="show"
    @click.stop="dialog=true"
  >
    <v-avatar
      size="32"
      color="rgb(18 66 106)"
      style="position: absolute; top: 6px; left: 3px; color:white; z-index:3;"
      class="font-weight-black elevation-3"
    >
      {{currentRating | ratingAge}}
    </v-avatar>
    <v-avatar
      size="128"
    >
      <img :src="imgSrc" :alt="currentRating">
    </v-avatar>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <rating-history-card
        v-if="dialog"
        :player="player"
        @close="dialog=false"
        @show-all="showAll"
        :rating="currentRatingObj"
        :more="view.allAdmin"
      ></rating-history-card>
    </v-dialog>
  </div>
</template>

<script>
import RatingHistoryCard from '@/components/Ratings/RatingExplainCard'
import BadgeSrc from '@/classes/BadgeSrc'
import { mapGetters } from 'vuex'

export default {
  props: ['player', 'view'],
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['theme']),
    ratings () {
      return this.player && this.player.vblRatings
    },
    ageDivision () {
      return this.ratings && this.ratings.ageDivision
    },
    age () {
      return this.ratings && this.ratings.ratingAge
    },
    show () {
      return this.ageDivision &&
        this.age < 19 &&
          !!this.currentRating &&
            this.currentRating !== 'Unrated' &&
              !this.currentRating.startsWith('UR') &&
                this.published &&
                  !this.noRatings
    },
    noRatings () {
      return this.player && this.player.tags.includes('noRatings')
    },
    published () {
      return !this.theme.props.includes('no-ratings')
    },
    selectedRating () {
      const tag = this.player && this.player.tags.find(f => f.startsWith('rating-'))
      if (tag) {
        const idString = tag.replace('rating-', '')
        return this.ratings && this.ratings.ratings.find(f => f.id === +idString)
      }
      return false
    },
    currentRating () {
      return this.selectedRating.currentRating || (this.ratings && this.ratings.displayRating.currentRating && this.ratings.displayRating.currentRating)
    },
    currentAgeRating () {
      return this.ratings && this.ratings.currentAgeRating.currentRating && this.ratings.currentAgeRating.currentRating
    },
    currentRatingObj () {
      return this.ratings && this.ratings.displayRating
    },
    imgSrc () {
      return BadgeSrc(this.currentRating)
    }
  },
  methods: {
    showAll () {
      this.dialog = false
      this.$emit('show-all')
    }
  },
  components: {
    RatingHistoryCard
  }
}
</script>

<style scoped>
.rating-sm {
  position: absolute;
  top: -30px;
  right: -5px;
  z-index: 2;
}
.rating-md {
  position: relative;
  margin-left: -74px;
}
</style>
